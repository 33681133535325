import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Link } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import * as moment from "moment"
import { axiosInstance } from "ConfigAxioxinstance"
import { toast } from "react-toastify"
import { JSONToCSVConvertor } from "common/jsontocsv"
import { csvDownloadData, humanize, investmentData } from "constants/common"
import ReactSelect from "constants/ReactSelect"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledTooltip,
  Input,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"

import Breadcrumbs from "components/Common/Breadcrumb"
import { distributorUsers } from "constants/common"
import Loader from "common/Loader"
import { DealManagementAccess } from "common/AccessManagement"
import {
  AccessId,
  addendumStatus,
  defaultSignatory,
} from "constants/ConstantFields"
import { sortingByAlphabet } from "constants/sort"

const DistributorAddendum = () => {
  const [orders, setOrders] = useState([])
  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [total, setTotal] = useState(0)

  const [loading, setLoading] = useState(false)
  const [users, setUsers] = useState([])
  const [modal, setModal] = useState(false)
  const [selectedOption, setSelectedOption] = useState({})
  const [file, setFile] = useState(null)
  const [addendumFormState, setAddendumFormState] = useState({})
  const [selectedAddendum, setSelectedAddendum] = useState({})
  const [investments, setInvestments] = useState([])
  const [distributorAgreementId, setDistributorAgreementId] = useState()
  const [deactivateModal, setDeactivateModal] = useState(false)
  const [selectedInvestment, setSelectedInvestment] = useState(null)
  const [uploadModal, setUploadModal] = useState(false)
  const [addendumDocUrl, setAddendumDocUrl] = useState("")
  const [userFilter, setUserFilter] = useState("")
  const [investmentFilter, setInvestmentFilter] = useState({})
  const [finalSelectedRows, setFinalSelectedRows] = useState([])
  const [activeStatusModal, setActiveStatusModal] = useState(false)
  const [addendumStatusFilter, setAddendumStatusFilter] = useState({})
  const [sendForSignModal, setSendForSignModal] = useState(false)
  const [consent, setConsent] = useState(false)
  const [cancelAddendumModal, setCancelAddendumModal] = useState(false)
  const [cancelConsent, setCancelConsent] = useState(false)
  const [oldAddendums, setOldAddendums] = useState([])
  const [selectedOldAddendum, setSelectedOldAddendum] = useState(null)

  const [selectedRadioOption, setSelectedRadioOption] = useState("edit")

  const filterUrl = `${userFilter.length >= 3 ? `&name=${userFilter}` : ``}${
    investmentFilter?.id ? `&investmentId=${investmentFilter.id}` : ""
  }${
    addendumStatusFilter?.id ? `&signing_status=${addendumStatusFilter.id}` : ""
  }`
  const masterData = async () => {
    setLoading(true)
    try {
      const response = await axiosInstance.get(
        `/distributor-addendum?$limit=${limit}&$skip=${skip}&$sort[updated_at]=-1${filterUrl}`
      )

      if (response) {
        setOrders(response.data?.data || response.data)
        setTotal(response.data.total)
        let pages = Math.ceil(
          (response.data?.total || response.total) /
            (response.data?.limit || response.limit)
        )
        setTotalPage(pages)
      }
    } catch (error) {
      toast.error(error?.message)
    }
    setLoading(false)
  }

  useEffect(async () => {
    setLoading(true)
    const usersListRes = await distributorUsers()
    const investmentRes = await investmentData("Approved", "Closed", "Exited")
    const usersList = usersListRes?.data?.data || usersListRes?.data
    const investments = investmentRes?.data?.data || investmentRes?.data

    if (usersList && investments) {
      const distributors = usersList.filter(user => {
        if (user.roleId && user.role?.department == "Distributor") {
          user.user_name = user?.distributor_kyc?.name
          return true
        }
      })
      setInvestments(investments)
      setUsers(distributors)
      setLoading(false)
    } else {
      toast.error("something went wrong")
      setLoading(false)
    }
  }, [])
  const toggleDeactivateModal = () => {
    setDeactivateModal(prev => !prev)
  }

  const handleDeactiveModalOpen = () => {
    setSelectedInvestment(null)
    toggleDeactivateModal()
  }
  const handleDeactiveAddendum = async () => {
    try {
      if (!selectedInvestment) {
        toast.error("select an investment")
        return
      }
      const payload = {
        investmentId: selectedInvestment.id,
      }
      setLoading(true)
      const res = await axiosInstance.post(`deactivate-bulk-addendum`, payload)
      if (res) {
        toast.success(res.data.message || "Addendum Deactivated Successfully")
        masterData()
        toggleDeactivateModal()
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(async () => {
    masterData()
  }, [investmentFilter, limit, skip, addendumStatusFilter])

  const handleNext = prev => {
    setSkip(prev + limit)
    let page = (prev + limit) / limit + 1
    setCurrentPage(page)
  }

  const handlePrevious = prev => {
    setSkip(prev - limit)
    let page = (prev - limit) / limit + 1
    setCurrentPage(page)
  }

  const selectRow = {
    mode: "checkbox",
    clickToSelect: false,
    clickToExpand: true,
    onSelect: (row, isSelect) => {
      if (isSelect) {
        setFinalSelectedRows([...finalSelectedRows, row])
      } else {
        setFinalSelectedRows(finalSelectedRows.filter(r => r.id !== row.id))
      }
    },
    onSelectAll: (isSelect, rows) => {
      setFinalSelectedRows(isSelect ? rows : [])
    },
    selected: finalSelectedRows.map(row => row.id),
  }
  const handleFilterChange = async (e, key) => {
    switch (key) {
      case "user":
        setUserFilter(e.target.value)
        break
      case "projectType":
        setInvestmentFilter(e)
        break
      case "addendum_status":
        setAddendumStatusFilter(e)
        break
      case "limit":
        setLimit(Number(e.target.value))
        break
      default:
        break
    }
    setSkip(0)
    setCurrentPage(1)
  }

  const onChangePagination = e => {
    const { value } = e.target
    if (value < 1 || value > totalPage) {
      return
    }
    setCurrentPage(value)
    if (value) {
      setSkip((value - 1) * limit)
    }
  }

  useEffect(() => {
    if (!userFilter || userFilter?.length >= 3) {
      masterData()
    }
  }, [userFilter])

  const accessRestriction = DealManagementAccess(AccessId?.DISTRIBUTOR_PORTAL)

  const handleMultipleId = type => {
    if (!finalSelectedRows.length) {
      toast.error("Please select a row")
      return false
    }
    if (finalSelectedRows.length > 1) {
      toast.error("Please select only one row")
      return
    }
    switch (type) {
      case "upload":
        if (finalSelectedRows[0].signing_status == "SIGNED") {
          toast.error("Addendum already signed")
          break
        }
        toggleUploadModal()

        break

      default:
        break
    }
  }
  const handleSignActionClick = item => {
    setSelectedAddendum(item)
    setSendForSignModal(true)
    setConsent(false)
  }
  const toggleSendForSignModal = () => {
    setSendForSignModal(!sendForSignModal)
  }
  const digioResData = row => {
    const data =
      row.webhook_data?.payload?.document?.signing_parties ||
      row.signing_request_data?.signing_parties ||
      []
    const obj = {
      signer_one: {
        name: "",
        email: "",
        status: "",
        sign_type: "",
      },
      signer_two: {
        name: "",
        email: "",
        status: "",
        sign_type: "",
      },
    }

    if (data.length) {
      for (let item of data) {
        if (item.identifier.toLowerCase() == row.distributor.email) {
          obj.signer_two.sign_type = item.signature_type.toUpperCase()
          obj.signer_two.email = item.identifier
          obj.signer_two.name = item.name
          obj.signer_two.status = item.status
        } else {
          obj.signer_one.sign_type = item.signature_type.toUpperCase()
          obj.signer_one.email = item.identifier
          obj.signer_one.name = item.name
          obj.signer_one.status = item.status
        }
      }
    }
    return obj
  }

  const toggleCancelAddendumModal = () => {
    setCancelAddendumModal(!cancelAddendumModal)
  }

  const handleCancelActionClick = item => {
    setSelectedAddendum(item)
    setCancelConsent(false)
    toggleCancelAddendumModal()
  }

  const handleCancelAddendum = async (e, values) => {
    try {
      setLoading(true)
      const res = await axiosInstance.delete(
        `/digio-signing-request/${selectedAddendum.digio_document_id}?type=distributor_addendum`
      )
      if (res) {
        toast.success("Sign request cancelled successfully")
        await masterData()
        toggleCancelAddendumModal()
      }
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }

  const AddendumColumns = download => {
    let cols = []
    if (!download) {
      cols.push(
        {
          dataField: "action",
          isDummyField: true,
          text: "Edit",
          // eslint-disable-next-line react/display-name
          formatter: (cellContent, order) => (
            <>
              <div className="d-flex gap-3">
                {accessRestriction >= 3 ||
                accessRestriction === "SuperAdmin" ? (
                  <>
                    {order.signing_status == "CREATED" ||
                    order.signing_status == "NOT_CREATED" ||
                    order.signing_status == "CANCELLED" ? (
                      <Link
                        to="#"
                        className="text-success"
                        onClick={() => handleOrderClick(order)}
                      >
                        <i
                          className="mdi mdi-pencil font-size-18"
                          id="edittooltip"
                        />
                        <UncontrolledTooltip
                          placement="top"
                          target="edittooltip"
                        >
                          Edit
                        </UncontrolledTooltip>
                      </Link>
                    ) : (
                      "NA"
                    )}
                  </>
                ) : (
                  "NA"
                )}
                {/* {accessRestriction === "SuperAdmin" ? (
              <Link
                className="text-danger"
                onClick={() => handleDeleteOrder(order)}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            ) : (
              "NA"
            )} */}
              </div>
            </>
          ),
        },
        {
          dataField: "",
          text: "Action",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cellContent, row) =>
            row.signing_status == "CREATED" && row.unsigned_doc_url ? (
              <Link to="#" className="text-primary">
                <Button
                  type="button"
                  color="primary"
                  className="btn-md btn-rounded"
                  onClick={() => handleSignActionClick(row)}
                >
                  Sign
                </Button>
              </Link>
            ) : (
              <>
                {row.signing_status == "SENT_FOR_SIGN" ? (
                  <Link to="#" className="text-primary">
                    <Button
                      type="button"
                      color="danger"
                      className="btn-md btn-rounded"
                      onClick={() => handleCancelActionClick(row)}
                    >
                      Cancel
                    </Button>
                  </Link>
                ) : (
                  "NA"
                )}
              </>
            ),
        }
      )
    }
    cols.push(
      {
        dataField: "legal_entity_name",
        text: "Legal Entity Name",
        sort: true,
        formatter: (cellContent, row) =>
          row?.distributor?.distributor_kyc?.name,
      },
      {
        dataField: "investment.project_name",
        text: "Project Name",
        sort: true,
      },
      {
        dataField: "status",
        text: "Active Status",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => (
          <span
            className={`badge badge-pill font-size-12 badge-soft-${
              row.status ? "success" : "danger"
            }`}
          >
            {row.status ? "Active" : "Inactive"}
          </span>
        ),
      },

      {
        dataField: "addendum_no",
        text: "Addendum No.",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => (
          <div className="text-end">{cellContent}</div>
        ),
      },
      {
        dataField: "signing_status",
        text: "Document Status",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => {
          const status = addendumStatus.find(obj => obj.id == cellContent)
          return (
            <span
              className={`badge badge-pill font-size-12 badge-soft-${
                status ? status?.colorClassName : "secondary"
              } text-capitalize`}
            >
              {status?.statusText ||
                cellContent?.toLowerCase()?.replaceAll("_", " ")}
            </span>
          )
        },
      },
      {
        dataField: "upfront_fee_applicable_date",
        text: "Upfront Fee Applicable Date",
        sort: true,
        formatter: (cellContent, row) =>
          row?.upfront_fee_applicable_date
            ? handleValidDate(row?.upfront_fee_applicable_date)
            : "NA",
      },
      {
        dataField: "upfront_fee_percent",
        text: "Upfront Fee percent",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => (
          <div className="text-end">{Number(cellContent).toFixed(2)}</div>
        ),
      },

      {
        dataField: "unsigned_doc_url",
        text: "Unsigned Addendum",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, order) =>
          order.unsigned_doc_url ? (
            <>
              <div className="d-flex gap-3">
                <Link
                  to="#"
                  className="text-primary"
                  onClick={() => handleDoc(order?.unsigned_doc_url)}
                >
                  <svg viewBox="0 0 24 24" width="26px" fill={"#556ee6"}>
                    <path d="M6,2A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2H6M6,4H13V9H18V20H6V4M8,12V14H16V12H8M8,16V18H13V16H8Z" />
                  </svg>
                </Link>
              </div>
            </>
          ) : (
            "NA"
          ),
      },
      {
        dataField: "addendum_doc_url",
        text: "Signed Addendum",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, order) =>
          order.addendum_doc_url ? (
            <>
              <div className="d-flex gap-3">
                <Link
                  to="#"
                  className="text-primary"
                  onClick={() => handleDoc(order?.addendum_doc_url)}
                >
                  <svg viewBox="0 0 24 24" fill={"#556ee6"} width="26px">
                    <path d="M19.7 12.9L14 18.6H11.7V16.3L17.4 10.6L19.7 12.9M23.1 12.1C23.1 12.4 22.8 12.7 22.5 13L20 15.5L19.1 14.6L21.7 12L21.1 11.4L20.4 12.1L18.1 9.8L20.3 7.7C20.5 7.5 20.9 7.5 21.2 7.7L22.6 9.1C22.8 9.3 22.8 9.7 22.6 10C22.4 10.2 22.2 10.4 22.2 10.6C22.2 10.8 22.4 11 22.6 11.2C22.9 11.5 23.2 11.8 23.1 12.1M3 20V4H10V9H15V10.5L17 8.5V8L11 2H3C1.9 2 1 2.9 1 4V20C1 21.1 1.9 22 3 22H15C16.1 22 17 21.1 17 20H3M11 17.1C10.8 17.1 10.6 17.2 10.5 17.2L10 15H8.5L6.4 16.7L7 14H5.5L4.5 19H6L8.9 16.4L9.5 18.7H10.5L11 18.6V17.1Z" />
                  </svg>
                </Link>
              </div>
            </>
          ) : (
            "NA"
          ),
      },
      {
        dataField: download ? "signer_one_name" : "",
        text: "Signer 1 Name",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => digioResData(row).signer_one.name,
      },
      {
        dataField: download ? "signer_one_email" : "",
        text: "Signer 1 Email",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => digioResData(row).signer_one.email,
      },
      {
        dataField: download ? "signer_one_sign_type" : "",
        text: "Signer 1 Sign Type",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => digioResData(row).signer_one.sign_type,
      },
      {
        dataField: download ? "signer_one_status" : "",
        text: "Signer 1 Status",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => (
          <div className="text-capitalize">
            {digioResData(row).signer_one.status}
          </div>
        ),
      },
      {
        dataField: download ? "signer_two_name" : "",
        text: "Signer 2 Name",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => digioResData(row).signer_two.name,
      },
      {
        dataField: download ? "signer_two_email" : "",
        text: "Signer 2 Email",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => digioResData(row).signer_two.email,
      },
      {
        dataField: download ? "signer_two_sign_type" : "",
        text: "Signer 2 Sign Type",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => digioResData(row).signer_two.sign_type,
      },
      {
        dataField: download ? "signer_two_status" : "",
        text: "Signer 2 Status",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => (
          <div className="text-capitalize">
            {digioResData(row).signer_two.status}
          </div>
        ),
      },
      { dataField: "distributor.email", text: "Email", sort: true },

      {
        dataField: "signed_on",
        text: "Signed On",
        sort: true,
        formatter: (cellContent, row) =>
          row?.signed_on ? handleValidDate(row?.signed_on) : "NA",
      },

      {
        dataField: "additional_fee_percent",
        text: "Trail Fee Percent",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => (
          <div className="text-end">{cellContent}</div>
        ),
      },
      {
        dataField: "additional_fee_payment_frequency",
        text: "Trail Fee Payment Frequency",
        sort: true,
      },
      {
        dataField: "trail_fee_applicable_days",
        text: "Trail Fee Applicable Days",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => (
          <div className="text-end">{cellContent}</div>
        ),
      },
      {
        dataField: "minimum_aggregate_face_value",
        text: "Minimum Business Volume",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => (
          <div className="text-end">{cellContent}</div>
        ),
      },
      { dataField: "distributor.user_name", text: "User Name", sort: true },
      { dataField: "distributor.phone", text: "Phone", sort: true },
      {
        dataField: "signatory",
        text: "Authorised Signatory",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, order) =>
          order?.distributor?.distributor_kyc?.signatory || "",
      },
      {
        dataField: "remarks",
        text: "Remarks",
        sort: true,
      },
      {
        dataField: "id",
        text: "Distributor Addendum Id",
        sort: true,
      }
    )

    return cols
  }

  const setAddendumUser = async e => {
    setLoading(true)
    try {
      const distributorAgreement = await axiosInstance.get(
        `/distributor-agreement?distributorId=${e.id}`
      )

      if (distributorAgreement) {
        const id = distributorAgreement?.data?.data[0]?.id
        if (id) {
          setDistributorAgreementId(id)
        } else {
          setDistributorAgreementId("null")
          toast.error("No Agreement found for this user")
        }
      }
    } catch (error) {
      toast.error(error?.message)
    }
    setLoading(false)
    setSelectedOption(e)
  }

  const handleOrderClick = async selected => {
    try {
      setOldAddendums([])
      setSelectedRadioOption("edit")
      setSelectedOldAddendum(null)
      setLoading(true)
      const res = await axiosInstance.get(
        `/distributor-addendum?investmentId=${selected?.investmentId}&distributorId=${selected.distributorId}&$sort[addendum_no]=-1`
      )
      if (res) {
        res.data.data.forEach(item => {
          item["label"] = `Addendum No.: ${item.addendum_no}`
        })

        setOldAddendums(res.data.data.filter(item => item.id != selected.id))
        setSelectedAddendum(selected)
        setSelectedOption({
          id: selected.distributorId,
          ...selected.distributor,
        })
        setAddendumFormState({
          upfrontFeePercent: selected?.upfront_fee_percent,
          upfrontFeeApplicableDate: selected?.upfront_fee_applicable_date,
          additionalFeePercent: selected?.additional_fee_percent,
          additionalFeePaymentFrequency:
            selected?.additional_fee_payment_frequency,
          minimumAggregateFaceValue: selected?.minimum_aggregate_face_value,
          signedOn: selected?.signed_on,
          distributorId: selected?.distributorId,
          investmentId: selected?.investmentId,
          distributorAgreementId: selected?.distributorAgreementId,
          trail_fee_applicable_days: selected?.trail_fee_applicable_days,
          status: selected?.status,
          remarks: selected?.remarks,
        })
        toggle()
      }
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }

  const handleDoc = async file => {
    setLoading(true)
    try {
      const response = await axiosInstance.post("/get-signed-url", {
        fileName: file,
      })
      if (response) {
        window.open(response?.data)
      }
    } catch (error) {
      toast.error(error?.message)
    }
    setLoading(false)
  }

  const handleValidOrderSubmit = async (e, values) => {
    if (!selectedOption?.id) {
      toast.error("select a user")
      return false
    }
    setLoading(true)
    const distributorAddendumData = {
      upfront_fee_percent: addendumFormState.upfrontFeePercent,
      upfront_fee_applicable_date: addendumFormState.upfrontFeeApplicableDate,
      additional_fee_percent: addendumFormState.additionalFeePercent,
      additional_fee_payment_frequency:
        addendumFormState.additionalFeePaymentFrequency || "NOT_APPLICABLE",
      minimum_aggregate_face_value: addendumFormState.minimumAggregateFaceValue,
      signed_on: addendumFormState.signedOn,
      distributorId: selectedOption?.id,
      investmentId: addendumFormState.investmentId,
      distributorAgreementId:
        distributorAgreementId || addendumFormState.distributorAgreementId,
      trail_fee_applicable_days: addendumFormState.trail_fee_applicable_days,
      status: addendumFormState.status,
      remarks: addendumFormState.remarks,
    }
    try {
      const response = selectedAddendum?.id
        ? await axiosInstance.patch(
            `distributor-addendum/${selectedAddendum?.id}`,
            distributorAddendumData
          )
        : await axiosInstance.post(
            `distributor-addendum`,
            distributorAddendumData
          )
      if (response) {
        toast.success(
          `Addendum ${selectedAddendum?.id ? "edited" : "created"} successfully`
        )

        const pdfResponse = await axiosInstance.post(
          "/generate-distributor-pdf",
          {
            type: "addendum",
            distributorAddendumId: response?.data?.id,
          }
        )
        if (pdfResponse) {
          toast.success("PDF generated successfully!")
        }
      }
    } catch (error) {
      throw error
    } finally {
      setSelectedAddendum({})
      setSelectedOption({})
      masterData()
      toggle()
    }
    const usersRes = await distributorUsers()
    if (usersRes) {
      const users = usersRes?.data?.data || usersRes?.data
      const distributors = users?.filter(user => {
        if (user.roleId && user.role?.department == "Distributor") {
          user.user_name = user?.distributor_kyc?.name
          return true
        }
      })
      setUsers(distributors)
    } else {
      toast.error("something went wrong")
    }
    setLoading(false)
  }
  const toggle = () => {
    setModal(!modal)
  }

  const toggleUploadModal = () => {
    setUploadModal(!uploadModal)
  }
  const toggleActiveStatusModal = () => {
    setActiveStatusModal(!activeStatusModal)
  }

  const handleUploadAddendum = async () => {
    const updateDoc = {
      addendum_doc_url: addendumDocUrl,
      is_addendum_signed: true,
      signing_status: "SIGNED",
    }
    try {
      const response = await axiosInstance.patch(
        `distributor-addendum/${finalSelectedRows[0]?.id}`,
        updateDoc
      )
      if (response) {
        toast.success("Successfully Updated")
        masterData()
        setFinalSelectedRows([])
        toggleUploadModal()
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  const handleValidDate = date => {
    const date1 = moment(date).format("DD MMM Y")
    return date1
  }
  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]

  const downloadData = async () => {
    try {
      setLoading(true)

      const fileName = "Distributor Addendum"

      const res = await axiosInstance.get(
        `/distributor-addendum?$sort[updated_at]=-1${filterUrl}`
      )
      if (res) {
        const data = res.data?.data
        const csvTableHeaders = AddendumColumns(true)
        const str = JSON.stringify(data)
        const arr = JSON.parse(str)
        arr?.forEach(item => {
          item.legal_entity_name = item?.distributor?.distributor_kyc?.name
          item.upfront_fee_applicable_date = item.upfront_fee_applicable_date
            ? handleValidDate(item.upfront_fee_applicable_date)
            : ""
          item.upfront_fee_percent = Number(item.upfront_fee_percent).toFixed(2)
          item.status = item.status ? "Active" : "Inactive"
          item.signed_on = item.signed_on ? handleValidDate(item.signed_on) : ""
          item.signatory = item?.distributor?.distributor_kyc?.signatory || ""
          const digioRes = digioResData(item)
          item.signer_one_name = digioRes.signer_one.name
          item.signer_one_email = digioRes.signer_one.email
          item.signer_one_sign_type = digioRes.signer_one.sign_type
          item.signer_one_status = digioRes.signer_one.status

          item.signer_two_name = digioRes.signer_two.name
          item.signer_two_email = digioRes.signer_two.email
          item.signer_two_sign_type = digioRes.signer_two.sign_type
          item.signer_two_status = digioRes.signer_two.status
        })
        const downladableArr = csvDownloadData(csvTableHeaders, arr)
        JSONToCSVConvertor(downladableArr, fileName, true)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      toast.error(error?.message)
    }
  }
  // const handleDeleteOrder = async order => {
  //   setLoading(true)
  //   var r = confirm(`Are you sure want to delete this user`)
  //   if (r == true) {
  //     try {
  //       const response = await axiosInstance.delete(
  //         `distributor-addendum/${order.id}`
  //       )
  //       if (response) {
  //         masterData()
  //         toast.success("Successfully Deleted")
  //       }
  //     } catch (error) {
  //       toast.error(error.message)
  //     }
  //   }
  //   setLoading(false)
  // }

  const handleInitiateAgreement = async () => {
    setSelectedRadioOption("edit")
    setSelectedAddendum({})
    setOldAddendums([])
    setSelectedOldAddendum(null)
    setLoading(true)
    const usersRes = await distributorUsers()
    if (usersRes) {
      const users = usersRes?.data?.data || usersRes?.data
      const distributors = users?.filter(user => {
        if (user.roleId && user.role?.department == "Distributor") {
          user.user_name = user?.distributor_kyc?.name
          return true
        }
      })
      setUsers(distributors)
      setLoading(false)
    } else {
      toast.error("something went wrong")
      setLoading(false)
    }
    setSelectedOption({})
    setAddendumFormState({})
    toggle()
  }

  const handleFileUpload = e => {
    setFile(e.target.files[0])
  }

  const handleOnChange = e => {
    const { name, value } = e.target
    switch (name) {
      case "upfrontFeePercent":
        setAddendumFormState({
          ...addendumFormState,
          upfrontFeePercent: value < 0 ? Math.abs(value) : value,
        })
        break
      case "upfrontFeeApplicableDate":
        setAddendumFormState({
          ...addendumFormState,
          upfrontFeeApplicableDate: value,
        })
        break
      case "additionalFeePercent":
        setAddendumFormState({
          ...addendumFormState,
          additionalFeePercent: value < 0 ? Math.abs(value) : value,
        })
        break
      case "additionalFeePaymentFrequency":
        setAddendumFormState({
          ...addendumFormState,
          additionalFeePaymentFrequency: value,
        })
        break
      case "minimumAggregateFaceValue":
        setAddendumFormState({
          ...addendumFormState,
          minimumAggregateFaceValue: value < 0 ? Math.abs(value) : value,
        })
        break

      case "signedOn":
        setAddendumFormState({
          ...addendumFormState,
          signedOn: value,
        })
        break
      case "trail_fee_applicable_days":
        setAddendumFormState({
          ...addendumFormState,
          trail_fee_applicable_days: value,
        })
        break
      case "investmentId":
        setAddendumFormState({
          ...addendumFormState,
          investmentId: value,
        })
        break
      case "open_status":
        setAddendumFormState({
          ...addendumFormState,
          status: value,
        })
        break
      case "remarks":
        setAddendumFormState({
          ...addendumFormState,
          remarks: value,
        })
        break
      default:
        break
    }
  }

  useEffect(async () => {
    if (file) {
      setLoading(true)
      const formData = new FormData()
      formData.append("file", file)
      formData.append("userId", finalSelectedRows[0]?.distributorId)
      try {
        const response = await axiosInstance.post(
          `/file-upload/?type=distributor_addendum_docs&userId=${finalSelectedRows[0]?.distributorId}`,
          formData
        )
        if (response) {
          setAddendumDocUrl(response?.data?.[0]?.name)
          setLoading(false)
        }
      } catch (error) {
        toast.error(error?.message)
        setLoading(false)
      }
    }
  }, [file])

  const handleUpdateActiveStatus = async (e, values) => {
    const obj = {
      status: values.open_status,
      investmentId: finalSelectedRows[0].investmentId,
      distributorAgreementId: finalSelectedRows[0].distributorAgreementId,
    }
    try {
      setLoading(true)
      const response = await axiosInstance.patch(
        `distributor-addendum/${finalSelectedRows[0]?.id}`,
        obj
      )
      if (response) {
        toast.success("Successfully Updated")
        masterData()
        setFinalSelectedRows([])
        toggleActiveStatusModal()
      }
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }

  const handleSendForSigning = async (e, values) => {
    try {
      setLoading(true)
      const response = await axiosInstance.post(
        `/digio-signing-request?type=distributor_addendum`,
        {
          distributorAddendumId: selectedAddendum?.id,
          defaultSignatory: {
            name: values.name,
            email: values.email,
          },
          signer_one_sign_type: values.signer_one_sign_type || "AADHAAR",
          signer_two_sign_type: values.signer_two_sign_type || "AADHAAR",
        }
      )
      if (response) {
        toast.success("Sent for sign")
        masterData()
        toggleSendForSignModal()
      }
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }

  const handleSendForApproval = async () => {
    try {
      setLoading(true)
      const res = await axiosInstance.patch(
        `/distributor-addendum/${selectedAddendum.id}`,
        {
          oldAddendumId: selectedOldAddendum.id,
          signing_status: "PENDING_APPROVAL",
        }
      )
      if (res) {
        await masterData()
        toast.success("Sent for approval")
        toggle()
        setOldAddendums([])
        setSelectedOldAddendum(null)
      }
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }
  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs breadcrumbItem="Distributor Addendum" />
          <Row>
            <Col xs="12">
              <Row className="mb-2 row justify-content-between">
                <Col md={2}>
                  <select
                    className="form-select w-75"
                    value={limit}
                    onChange={e => handleFilterChange(e, "limit")}
                  >
                    {[10, 30, 50, 100].map(pageSize => (
                      <option key={pageSize} value={pageSize}>
                        Show {pageSize}
                      </option>
                    ))}
                  </select>
                </Col>
                <div className="col-auto">
                  {(accessRestriction >= 2 ||
                    accessRestriction === "SuperAdmin") && (
                    <Button
                      type="button"
                      color="success"
                      className="btn-rounded  mb-2 me-2"
                      onClick={handleInitiateAgreement}
                      style={{ marginRight: "3px" }}
                    >
                      <i className="mdi mdi-plus me-1" /> Add
                    </Button>
                  )}
                  {(accessRestriction >= "4" ||
                    accessRestriction === "SuperAdmin") && (
                    <Button
                      type="button"
                      color="primary"
                      className="btn-rounded mb-2 me-2"
                      style={{ marginRight: "3px" }}
                      onClick={() => handleMultipleId("upload")}
                      disabled={
                        !(
                          finalSelectedRows?.length == 1 &&
                          finalSelectedRows[0]?.signing_status ==
                            "SENT_FOR_SIGN"
                        )
                      }
                    >
                      <i className="mdi mdi-arrow-up-bold-circle"></i> Upload
                      Signed Addendum
                    </Button>
                  )}
                  {(accessRestriction >= "4" ||
                    accessRestriction === "SuperAdmin") && (
                    <Button
                      type="button"
                      color="primary"
                      className="btn-rounded mb-2 me-2"
                      onClick={toggleActiveStatusModal}
                      disabled={finalSelectedRows?.length != 1}
                      style={{ marginRight: "3px" }}
                    >
                      <i className="mdi mdi-pencil me-1"></i> Update Active
                      Status
                    </Button>
                  )}
                  {(accessRestriction >= "4" ||
                    accessRestriction === "SuperAdmin") && (
                    <Button
                      type="button"
                      color="primary"
                      className="btn-rounded mb-2 me-2"
                      onClick={handleDeactiveModalOpen}
                      style={{ marginRight: "3px" }}
                    >
                      <i className="mdi mdi-pencil me-1"></i> Deactivate
                      Addendum
                    </Button>
                  )}
                  {(accessRestriction >= "4" ||
                    accessRestriction === "SuperAdmin") && (
                    <Button
                      type="button"
                      color="primary"
                      className="btn-rounded mb-2 me-2"
                      onClick={downloadData}
                      style={{ marginRight: "3px" }}
                    >
                      <i className="mdi mdi-arrow-down-bold-circle"></i>{" "}
                      Download
                    </Button>
                  )}
                </div>
              </Row>
              <Card>
                <CardBody>
                  <ToolkitProvider
                    keyField="id"
                    data={orders}
                    columns={AddendumColumns()}
                    bootstrap4
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row className="mb-2 row">
                          <Col md={3}>
                            <div className="position-relative">
                              <label>Search User</label>
                              <input
                                onChange={e => handleFilterChange(e, "user")}
                                id="search-bar-0"
                                type="text"
                                className="form-control rounded custom-input-height"
                                placeholder={`Name, Email, Phone, Pan Name or Pan`}
                                value={userFilter || ""}
                              />
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className="mb-3">
                              <label>Project Name</label>
                              <ReactSelect
                                users={investments}
                                setSelectedOption={e =>
                                  handleFilterChange(e, "projectType")
                                }
                                multiOptionLabel={true}
                                optionLabelKeys={["project_name"]}
                                isClearable={true}
                              />
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className="mb-3">
                              <label>Document Status</label>
                              <ReactSelect
                                users={addendumStatus}
                                setSelectedOption={e =>
                                  handleFilterChange(e, "addendum_status")
                                }
                                multiOptionLabel={true}
                                optionLabelKeys={["statusText"]}
                                isClearable={true}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl="12">
                            <BootstrapTable
                              keyField="id"
                              bordered={false}
                              striped={false}
                              defaultSorted={defaultSorted}
                              selectRow={selectRow}
                              columns={AddendumColumns()}
                              data={orders}
                              wrapperClasses={"table-responsive mb-4"}
                              classes={
                                "table align-middle table-nowrap table-check"
                              }
                              headerWrapperClasses={"table-light"}
                              {...toolkitProps.baseProps}
                            />
                            <Modal
                              isOpen={uploadModal}
                              toggle={toggleUploadModal}
                            >
                              <ModalHeader toggle={toggleUploadModal} tag="h4">
                                Upload Signed Addendum
                              </ModalHeader>
                              <ModalBody>
                                <AvForm onValidSubmit={handleUploadAddendum}>
                                  <Row form>
                                    <div className="row">
                                      <div className="mb-3">
                                        {loading && <Loader />}
                                        <AvField
                                          className="form-control"
                                          type="file"
                                          id="formFile"
                                          errorMessage="Please Enter valid input"
                                          onChange={handleFileUpload}
                                          name="addendumDocUrl"
                                          label="Upload Addendum Document"
                                        ></AvField>
                                        <p className="my-2">
                                          {addendumDocUrl
                                            ? `File uploaded: ${addendumDocUrl
                                                .split("/")
                                                .pop()}`
                                            : ""}
                                        </p>
                                      </div>
                                    </div>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <div className={"text-end mt-2"}>
                                        <button
                                          type="submit"
                                          className="btn btn-success save-user"
                                          disabled={loading}
                                        >
                                          SUBMIT
                                        </button>
                                      </div>
                                    </Col>
                                  </Row>
                                </AvForm>
                              </ModalBody>
                            </Modal>
                            <Modal
                              isOpen={sendForSignModal}
                              toggle={toggleSendForSignModal}
                            >
                              <ModalHeader
                                toggle={toggleSendForSignModal}
                                tag="h4"
                              >
                                Send for Sign
                              </ModalHeader>
                              <ModalBody>
                                <AvForm onValidSubmit={handleSendForSigning}>
                                  <h5 className="mb-3">
                                    Please verify the details of the signing
                                    parties before sending it for sign.
                                  </h5>
                                  <Row>
                                    <Col>
                                      <div className="row">
                                        <h5 className="mb-3">Signer 1</h5>
                                        <div className="mb-3 col-md-6">
                                          <AvField
                                            name="name"
                                            label="Name"
                                            type="text"
                                            errorMessage="Invalid value"
                                            value={defaultSignatory.name}
                                            validate={{
                                              required: { value: true },
                                            }}
                                          ></AvField>
                                        </div>
                                        <div className="mb-3 col-md-6">
                                          <AvField
                                            name="email"
                                            label="Email"
                                            type="text"
                                            errorMessage="Invalid value"
                                            value={defaultSignatory.email}
                                            validate={{
                                              required: { value: true },
                                            }}
                                          ></AvField>
                                        </div>
                                        <div className="mb-3">
                                          <AvField
                                            name="signer_one_sign_type"
                                            label="Sign Type"
                                            type="select"
                                            className="form-select"
                                            errorMessage="Invalid value"
                                          >
                                            <option
                                              value="AADHAAR"
                                              selected={true}
                                            >
                                              AADHAR
                                            </option>
                                            <option value="DSC">DSC</option>
                                            <option value="ELECTRONIC">
                                              ELECTRONIC
                                            </option>
                                          </AvField>
                                        </div>
                                        <h5 className="mb-3">Signer 2</h5>
                                        <div className="mb-3 col-md-6">
                                          <AvField
                                            name="distributor_name"
                                            label="Name"
                                            type="text"
                                            errorMessage="Invalid value"
                                            value={
                                              selectedAddendum?.distributor
                                                ?.distributor_kyc?.signatory ||
                                              selectedAddendum?.distributor
                                                ?.distributor_kyc?.name ||
                                              ""
                                            }
                                            disabled={true}
                                          ></AvField>
                                        </div>
                                        <div className="mb-3 col-md-6">
                                          <AvField
                                            name="distributor_email"
                                            label="Email"
                                            type="text"
                                            errorMessage="Invalid value"
                                            value={
                                              selectedAddendum?.distributor
                                                ?.email || ""
                                            }
                                            disabled={true}
                                          ></AvField>
                                        </div>
                                        <div className="mb-3">
                                          <AvField
                                            name="signer_two_sign_type"
                                            label="Sign Type"
                                            type="select"
                                            className="form-select"
                                            errorMessage="Invalid value"
                                          >
                                            <option
                                              value="AADHAAR"
                                              selected={true}
                                            >
                                              AADHAR
                                            </option>
                                            <option value="DSC">DSC</option>
                                            <option value="ELECTRONIC">
                                              ELECTRONIC
                                            </option>
                                          </AvField>
                                        </div>

                                        <div className="d-flex gap-1 mb-3">
                                          <AvField
                                            name="consent"
                                            type="checkbox"
                                            id="consent"
                                            checked={consent}
                                            onChange={e =>
                                              setConsent(e.target.checked)
                                            }
                                          />{" "}
                                          <label
                                            className="mb-0 font-size-14 text-muted"
                                            htmlFor="consent"
                                          >
                                            I have reviewed the document and
                                            verified the signer details
                                          </label>
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row className="justify-content-end mt-2">
                                    <div className="d-flex justify-content-end gap-2">
                                      <Button
                                        type="button"
                                        color="danger"
                                        className="save-user"
                                        onClick={() =>
                                          setSendForSignModal(false)
                                        }
                                      >
                                        Cancel
                                      </Button>
                                      <Button
                                        type="submit"
                                        color="success"
                                        className="save-user"
                                        disabled={!consent}
                                      >
                                        Confirm
                                      </Button>
                                    </div>
                                  </Row>
                                </AvForm>
                              </ModalBody>
                            </Modal>
                            <Modal
                              isOpen={deactivateModal}
                              toggle={toggleDeactivateModal}
                            >
                              <ModalHeader
                                toggle={toggleDeactivateModal}
                                tag="h4"
                              >
                                Bulk Deactivate Addendum
                              </ModalHeader>
                              <ModalBody>
                                <AvForm onValidSubmit={handleDeactiveAddendum}>
                                  <Row form>
                                    <div className="row">
                                      <div className="mb-3">
                                        <label>Project Name</label>
                                        <ReactSelect
                                          users={investments}
                                          setSelectedOption={
                                            setSelectedInvestment
                                          }
                                          selectedOption={selectedInvestment}
                                          multiOptionLabel={true}
                                          optionLabelKeys={["project_name"]}
                                          isClearable={true}
                                        />
                                      </div>
                                    </div>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <div className={"text-end mt-3"}>
                                        <button
                                          type="submit"
                                          className="btn btn-success save-user"
                                          disabled={loading}
                                        >
                                          SUBMIT
                                        </button>
                                      </div>
                                    </Col>
                                  </Row>
                                </AvForm>
                              </ModalBody>
                            </Modal>

                            <Modal
                              isOpen={activeStatusModal}
                              toggle={toggleActiveStatusModal}
                            >
                              <ModalHeader
                                toggle={toggleActiveStatusModal}
                                tag="h4"
                              >
                                Update Active Status
                              </ModalHeader>
                              <ModalBody>
                                <AvForm
                                  onValidSubmit={handleUpdateActiveStatus}
                                >
                                  <Row form>
                                    <div className="mb-3">
                                      <AvField
                                        name="open_status"
                                        label="Status"
                                        type="select"
                                        className="form-select"
                                        errorMessage="Invalid value"
                                        validate={{
                                          required: { value: true },
                                        }}
                                        value={
                                          finalSelectedRows[0]?.status?.toString() ||
                                          ""
                                        }
                                      >
                                        <option disabled value="">
                                          Select
                                        </option>
                                        <option value={true}>Active</option>
                                        <option value={false}>Inactive</option>
                                      </AvField>
                                    </div>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <div className={"text-end mt-3"}>
                                        <button
                                          type="submit"
                                          className="btn btn-success save-user"
                                          disabled={loading}
                                        >
                                          SUBMIT
                                        </button>
                                      </div>
                                    </Col>
                                  </Row>
                                </AvForm>
                              </ModalBody>
                            </Modal>

                            <Modal
                              isOpen={modal}
                              toggle={toggle}
                              className={"modal-lg"}
                            >
                              <ModalHeader toggle={toggle} tag="h4">
                                {selectedAddendum?.id
                                  ? "Edit Distributor Addendum"
                                  : "Distributor Addendum"}
                              </ModalHeader>
                              <ModalBody>
                                <AvForm onValidSubmit={handleValidOrderSubmit}>
                                  <Row
                                    form
                                    style={{
                                      color: "grey",
                                    }}
                                  >
                                    <div className="row">
                                      <div className="col-md-6">
                                        <div className="mb-3">
                                          <label>Distributor</label>
                                          {selectedOption.id ? (
                                            <ReactSelect
                                              users={users}
                                              selectedOption={{
                                                user_name:
                                                  selectedOption
                                                    ?.distributor_kyc?.name,
                                                email: selectedOption.email,
                                                phone: selectedOption.phone,
                                              }}
                                              isDisabled={
                                                selectedAddendum?.id
                                                  ? true
                                                  : false
                                              }
                                              setSelectedOption={
                                                setAddendumUser
                                              }
                                            />
                                          ) : (
                                            <ReactSelect
                                              users={users}
                                              setSelectedOption={
                                                setAddendumUser
                                              }
                                              isDisabled={
                                                selectedAddendum?.id
                                                  ? true
                                                  : false
                                              }
                                            />
                                          )}
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="mb-3">
                                          <AvField
                                            name="investmentId"
                                            label="Project Name"
                                            type="select"
                                            className="form-select"
                                            errorMessage="Invalid value"
                                            sort="true"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            value={
                                              addendumFormState.investmentId ||
                                              ""
                                            }
                                            disabled={
                                              distributorAgreementId ===
                                                "null" || selectedAddendum?.id
                                            }
                                            onChange={handleOnChange}
                                          >
                                            <option disabled value="">
                                              Select
                                            </option>
                                            {sortingByAlphabet(
                                              investments?.map(
                                                (item, index) => (
                                                  <option
                                                    key={item?.project_name}
                                                    value={item?.id}
                                                  >
                                                    {item?.project_name}
                                                  </option>
                                                )
                                              )
                                            )}
                                          </AvField>
                                        </div>
                                      </div>

                                      {selectedAddendum?.id ? (
                                        <div className="col-md-12 mt-2">
                                          <div className="d-flex gap-3 mb-3">
                                            <AvField
                                              type="radio"
                                              name="editOption"
                                              id="edit"
                                              label="Edit Current Addendum"
                                              value="edit"
                                              checked={
                                                selectedRadioOption === "edit"
                                              }
                                              style={{ marginLeft: "10px" }}
                                              onChange={e => {
                                                setSelectedRadioOption(
                                                  e.target.value
                                                )
                                                setSelectedOldAddendum(null)
                                              }}
                                            />
                                            <AvField
                                              type="radio"
                                              name="editOption"
                                              label="Use Old Addendum"
                                              id="old"
                                              value="old"
                                              checked={
                                                selectedRadioOption === "old"
                                              }
                                              style={{ marginLeft: "10px" }}
                                              onChange={e => {
                                                setSelectedRadioOption(
                                                  e.target.value
                                                )
                                              }}
                                            />
                                          </div>
                                          {selectedRadioOption == "old" ? (
                                            <>
                                              <div className="mb-3 col-md-6">
                                                <label>
                                                  Select Old Addendum
                                                </label>
                                                <ReactSelect
                                                  users={oldAddendums}
                                                  selectedOption={
                                                    selectedOldAddendum
                                                  }
                                                  setSelectedOption={
                                                    setSelectedOldAddendum
                                                  }
                                                  multiOptionLabel={true}
                                                  optionLabelKeys={["label"]}
                                                  isClearable={true}
                                                />
                                              </div>
                                              {selectedOldAddendum?.id ? (
                                                <div className="row">
                                                  <div className="col-md-6">
                                                    <div className="mb-3">
                                                      <AvField
                                                        name="upfrontFeePercent"
                                                        label="Upfront Fee Percent"
                                                        type="number"
                                                        min={0}
                                                        errorMessage="Invalid Value"
                                                        validate={{
                                                          required: {
                                                            value: true,
                                                          },
                                                        }}
                                                        value={
                                                          selectedOldAddendum?.upfront_fee_percent ||
                                                          ""
                                                        }
                                                        disabled={true}
                                                      ></AvField>
                                                    </div>
                                                  </div>
                                                  <div className="col-md-6">
                                                    <div className="mb-3">
                                                      <AvField
                                                        name="upfrontFeeApplicableDate"
                                                        label="Upfront Fee Applicable Date"
                                                        type="date"
                                                        errorMessage="Invalid Value"
                                                        validate={{
                                                          required: {
                                                            value: true,
                                                          },
                                                        }}
                                                        value={
                                                          selectedOldAddendum?.upfront_fee_applicable_date ||
                                                          ""
                                                        }
                                                        disabled={true}
                                                      />
                                                    </div>
                                                  </div>
                                                  <div className="col-md-6">
                                                    <div className="mb-3">
                                                      <AvField
                                                        name="additionalFeePercent"
                                                        label="Trail Fee Percent"
                                                        type="number"
                                                        errorMessage="Invalid Value"
                                                        min={0}
                                                        value={
                                                          selectedOldAddendum?.additional_fee_percent?.toString() ||
                                                          ""
                                                        }
                                                        disabled={true}
                                                      ></AvField>
                                                    </div>
                                                  </div>
                                                  <div className="col-md-6">
                                                    <div className="mb-3">
                                                      <AvField
                                                        name="additionalFeePaymentFrequency"
                                                        label="Trail Fee Payment Frequency"
                                                        type="select"
                                                        className="form-select"
                                                        errorMessage="Invalid value"
                                                        value={
                                                          selectedOldAddendum?.additional_fee_payment_frequency ||
                                                          ""
                                                        }
                                                        disabled={true}
                                                      >
                                                        <option
                                                          disabled
                                                          value=""
                                                        >
                                                          Select
                                                        </option>
                                                        <option value="NOT_APPLICABLE">
                                                          Not Applicable
                                                        </option>
                                                        <option value="MONTHLY">
                                                          Monthly
                                                        </option>
                                                        <option value="QUARTERLY">
                                                          Quarterly
                                                        </option>
                                                        <option value="HALF_YEARLY">
                                                          Half Yearly
                                                        </option>
                                                        <option value="ANNUALLY">
                                                          Yearly
                                                        </option>
                                                      </AvField>
                                                    </div>
                                                  </div>
                                                  <div className="col-md-6">
                                                    <div className="mb-3">
                                                      <AvField
                                                        name="trail_fee_applicable_days"
                                                        label="Trail Fee Start Days"
                                                        type="number"
                                                        errorMessage="Invalid Value"
                                                        validate={{
                                                          required: {
                                                            value: true,
                                                          },
                                                        }}
                                                        disabled={true}
                                                        value={
                                                          selectedOldAddendum?.trail_fee_applicable_days?.toString() ||
                                                          ""
                                                        }
                                                      />
                                                    </div>
                                                  </div>
                                                  <div className="col-md-6">
                                                    <div className="mb-3">
                                                      <AvField
                                                        name="minimumAggregateFaceValue"
                                                        label="Minimum Business Volume"
                                                        type="number"
                                                        errorMessage="Invalid Value"
                                                        min={0}
                                                        validate={{
                                                          required: {
                                                            value: true,
                                                          },
                                                        }}
                                                        value={
                                                          selectedOldAddendum?.minimum_aggregate_face_value?.toString() ||
                                                          ""
                                                        }
                                                        disabled={true}
                                                      ></AvField>
                                                    </div>
                                                  </div>
                                                  <div className="col-md-6">
                                                    <div className="mb-3">
                                                      <AvField
                                                        name="signedOn"
                                                        label="Signed On"
                                                        type="date"
                                                        errorMessage="Invalid Value"
                                                        validate={{
                                                          required: {
                                                            value: true,
                                                          },
                                                        }}
                                                        value={
                                                          selectedOldAddendum?.signed_on ||
                                                          ""
                                                        }
                                                        disabled={true}
                                                      />
                                                    </div>
                                                  </div>
                                                  <div className="col-md-6">
                                                    <div className="mb-3">
                                                      <AvField
                                                        name="open_status"
                                                        label="Active Status"
                                                        type="select"
                                                        className="form-select"
                                                        errorMessage="Invalid value"
                                                        validate={{
                                                          required: {
                                                            value: true,
                                                          },
                                                        }}
                                                        value={
                                                          selectedOldAddendum?.status?.toString() ||
                                                          ""
                                                        }
                                                        disabled={true}
                                                      >
                                                        <option
                                                          disabled
                                                          value=""
                                                        >
                                                          Select
                                                        </option>
                                                        <option value={true}>
                                                          Active
                                                        </option>
                                                        <option value={false}>
                                                          Inactive
                                                        </option>
                                                      </AvField>
                                                    </div>
                                                  </div>
                                                  <div className="col-md-6">
                                                    <div className="mb-3">
                                                      <AvField
                                                        name="remarks"
                                                        label="Remarks"
                                                        type="text"
                                                        errorMessage="Invalid Value"
                                                        validate={{
                                                          required: {
                                                            value: true,
                                                          },
                                                        }}
                                                        value={
                                                          selectedOldAddendum?.remarks ||
                                                          ""
                                                        }
                                                        disabled={true}
                                                      ></AvField>
                                                    </div>
                                                  </div>
                                                </div>
                                              ) : null}
                                            </>
                                          ) : null}
                                        </div>
                                      ) : null}

                                      {selectedRadioOption != "old" ? (
                                        <>
                                          <div className="col-md-6">
                                            <div className="mb-3">
                                              <AvField
                                                name="upfrontFeePercent"
                                                label="Upfront Fee Percent"
                                                type="number"
                                                min={0}
                                                errorMessage="Invalid Value"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={
                                                  addendumFormState.upfrontFeePercent ||
                                                  ""
                                                }
                                                disabled={
                                                  distributorAgreementId ===
                                                  "null"
                                                }
                                                onChange={handleOnChange}
                                              ></AvField>
                                            </div>
                                          </div>
                                          <div className="col-md-6">
                                            <div className="mb-3">
                                              <AvField
                                                name="upfrontFeeApplicableDate"
                                                label="Upfront Fee Applicable Date"
                                                type="date"
                                                errorMessage="Invalid Value"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={
                                                  addendumFormState?.upfrontFeeApplicableDate ||
                                                  ""
                                                }
                                                disabled={
                                                  distributorAgreementId ===
                                                  "null"
                                                }
                                                onChange={handleOnChange}
                                              />
                                            </div>
                                          </div>
                                          <div className="col-md-6">
                                            <div className="mb-3">
                                              <AvField
                                                name="additionalFeePercent"
                                                label="Trail Fee Percent"
                                                type="number"
                                                errorMessage="Invalid Value"
                                                min={0}
                                                value={
                                                  addendumFormState.additionalFeePercent?.toString() ||
                                                  ""
                                                }
                                                disabled={
                                                  distributorAgreementId ===
                                                  "null"
                                                }
                                                onChange={handleOnChange}
                                              ></AvField>
                                            </div>
                                          </div>
                                          <div className="col-md-6">
                                            <div className="mb-3">
                                              <AvField
                                                name="additionalFeePaymentFrequency"
                                                label="Trail Fee Payment Frequency"
                                                type="select"
                                                className="form-select"
                                                errorMessage="Invalid value"
                                                value={
                                                  addendumFormState.additionalFeePaymentFrequency ||
                                                  ""
                                                }
                                                disabled={
                                                  distributorAgreementId ===
                                                  "null"
                                                }
                                                onChange={handleOnChange}
                                              >
                                                <option disabled value="">
                                                  Select
                                                </option>
                                                <option value="NOT_APPLICABLE">
                                                  Not Applicable
                                                </option>
                                                <option value="MONTHLY">
                                                  Monthly
                                                </option>
                                                <option value="QUARTERLY">
                                                  Quarterly
                                                </option>
                                                <option value="HALF_YEARLY">
                                                  Half Yearly
                                                </option>
                                                <option value="ANNUALLY">
                                                  Yearly
                                                </option>
                                              </AvField>
                                            </div>
                                          </div>
                                          <div className="col-md-6">
                                            <div className="mb-3">
                                              <AvField
                                                name="trail_fee_applicable_days"
                                                label="Trail Fee Start Days"
                                                type="number"
                                                errorMessage="Invalid Value"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                disabled={
                                                  distributorAgreementId ===
                                                  "null"
                                                }
                                                value={
                                                  addendumFormState?.trail_fee_applicable_days?.toString() ||
                                                  ""
                                                }
                                                onChange={handleOnChange}
                                              />
                                            </div>
                                          </div>
                                          <div className="col-md-6">
                                            <div className="mb-3">
                                              <AvField
                                                name="minimumAggregateFaceValue"
                                                label="Minimum Business Volume"
                                                type="number"
                                                errorMessage="Invalid Value"
                                                min={0}
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={
                                                  addendumFormState?.minimumAggregateFaceValue?.toString() ||
                                                  ""
                                                }
                                                disabled={
                                                  distributorAgreementId ===
                                                  "null"
                                                }
                                                onChange={handleOnChange}
                                              ></AvField>
                                            </div>
                                          </div>
                                          <div className="col-md-6">
                                            <div className="mb-3">
                                              <AvField
                                                name="signedOn"
                                                label="Signed On"
                                                type="date"
                                                errorMessage="Invalid Value"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={
                                                  addendumFormState?.signedOn ||
                                                  ""
                                                }
                                                disabled={
                                                  distributorAgreementId ===
                                                  "null"
                                                }
                                                onChange={handleOnChange}
                                              />
                                            </div>
                                          </div>
                                          <div className="col-md-6">
                                            <div className="mb-3">
                                              <AvField
                                                name="open_status"
                                                label="Active Status"
                                                type="select"
                                                className="form-select"
                                                errorMessage="Invalid value"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={
                                                  addendumFormState.status?.toString() ||
                                                  ""
                                                }
                                                disabled={
                                                  distributorAgreementId ===
                                                  "null"
                                                }
                                                onChange={handleOnChange}
                                              >
                                                <option disabled value="">
                                                  Select
                                                </option>
                                                <option value={true}>
                                                  Active
                                                </option>
                                                <option value={false}>
                                                  Inactive
                                                </option>
                                              </AvField>
                                            </div>
                                          </div>
                                          <div className="col-md-6">
                                            <div className="mb-3">
                                              <AvField
                                                name="remarks"
                                                label="Remarks"
                                                type="text"
                                                errorMessage="Invalid Value"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={
                                                  addendumFormState.remarks ||
                                                  ""
                                                }
                                                disabled={
                                                  distributorAgreementId ===
                                                  "null"
                                                }
                                                onChange={handleOnChange}
                                              ></AvField>
                                            </div>
                                          </div>
                                        </>
                                      ) : null}
                                    </div>
                                  </Row>
                                  <Row>
                                    {
                                      <Col>
                                        <div className={"text-end mt-2"}>
                                          {selectedRadioOption == "old" ? (
                                            <button
                                              type="button"
                                              className="btn btn-success save-user"
                                              disabled={
                                                loading ||
                                                !selectedOldAddendum?.id
                                              }
                                              onClick={handleSendForApproval}
                                            >
                                              Send For Approval
                                            </button>
                                          ) : (
                                            <button
                                              type="submit"
                                              className="btn btn-success save-user"
                                              disabled={
                                                loading ||
                                                selectedOption.signing_status ==
                                                  "SIGNED" ||
                                                distributorAgreementId ===
                                                  "null"
                                              }
                                            >
                                              SAVE
                                            </button>
                                          )}
                                        </div>
                                      </Col>
                                    }
                                  </Row>
                                </AvForm>
                              </ModalBody>
                            </Modal>
                            <Modal
                              isOpen={cancelAddendumModal}
                              toggle={toggleCancelAddendumModal}
                            >
                              <ModalHeader
                                toggle={toggleCancelAddendumModal}
                                tag="h4"
                              >
                                Confirmation
                              </ModalHeader>
                              <ModalBody>
                                <AvForm onValidSubmit={handleCancelAddendum}>
                                  <Row>
                                    <h5>
                                      The Addendum has already been sent for
                                      Sign.
                                    </h5>
                                    <p className="font-size-15">
                                      {" "}
                                      {selectedAddendum?.webhook_data ? (
                                        <>
                                          Signer 1:{" "}
                                          <b>
                                            {
                                              selectedAddendum?.webhook_data
                                                ?.payload?.document
                                                ?.signing_parties[0]?.name
                                            }
                                          </b>
                                          <br />
                                          Status:{" "}
                                          <b>
                                            {humanize(
                                              selectedAddendum?.webhook_data
                                                ?.payload?.document
                                                ?.signing_parties[0]?.status
                                            )}
                                          </b>
                                          <br />
                                          <br />
                                          Signer 2:{" "}
                                          <b>
                                            {
                                              selectedAddendum?.webhook_data
                                                ?.payload?.document
                                                ?.signing_parties[1]?.name
                                            }
                                          </b>
                                          <br />
                                          Status:{" "}
                                          <b>
                                            {humanize(
                                              selectedAddendum?.webhook_data
                                                ?.payload?.document
                                                ?.signing_parties[1]?.status
                                            )}
                                          </b>
                                        </>
                                      ) : (
                                        <>
                                          Signer 1:{" "}
                                          <b>
                                            {
                                              selectedAddendum
                                                ?.signing_request_data
                                                ?.signing_parties[0]?.name
                                            }
                                          </b>
                                          <br />
                                          Status:{" "}
                                          <b>
                                            {humanize(
                                              selectedAddendum
                                                ?.signing_request_data
                                                ?.signing_parties[0]?.status
                                            )}
                                          </b>
                                          <br />
                                          <br />
                                          Signer 2:{" "}
                                          <b>
                                            {
                                              selectedAddendum
                                                ?.signing_request_data
                                                ?.signing_parties[1]?.name
                                            }
                                          </b>
                                          <br />
                                          Status:{" "}
                                          <b>
                                            {humanize(
                                              selectedAddendum
                                                ?.signing_request_data
                                                ?.signing_parties[1]?.status
                                            )}
                                          </b>
                                        </>
                                      )}
                                    </p>
                                    <div className="d-flex gap-1 my-3">
                                      <AvField
                                        name="cancel_consent"
                                        type="checkbox"
                                        id="cancelConsent"
                                        checked={cancelConsent}
                                        onChange={e =>
                                          setCancelConsent(e.target.checked)
                                        }
                                      />{" "}
                                      <label
                                        className="mb-0 font-size-14 text-muted"
                                        htmlFor="cancelConsent"
                                      >
                                        I want to cancel this sign request
                                      </label>
                                    </div>
                                  </Row>
                                  <Row className="justify-content-end">
                                    <div className="d-flex gap-2 justify-content-end">
                                      <Button
                                        type="button"
                                        color="danger"
                                        className="save-user"
                                        onClick={() =>
                                          setCancelAddendumModal(false)
                                        }
                                      >
                                        Close
                                      </Button>
                                      <Button
                                        type="submit"
                                        color="success"
                                        className="save-user"
                                        disabled={!cancelConsent}
                                      >
                                        Confirm
                                      </Button>
                                    </div>
                                  </Row>
                                </AvForm>
                              </ModalBody>
                            </Modal>
                          </Col>
                        </Row>
                        <Row className="justify-content-md-space-between justify-content-center align-items-center">
                          <Col className="col-12 col-md-auto mb-3">
                            {`Showing ${total ? skip + 1 + " to" : ""} ${
                              limit > total || limit + skip > total
                                ? total
                                : limit + skip
                            } rows of ${total}${
                              finalSelectedRows.length
                                ? ` | Selected rows: ${finalSelectedRows.length}`
                                : ""
                            }
                    `}
                          </Col>
                          <Col>
                            <Row className="justify-content-md-end justify-content-center align-items-center">
                              <Col className="col-md-auto">
                                <div className="d-flex gap-1">
                                  <Button
                                    color="primary"
                                    onClick={() => handlePrevious(limit)}
                                    disabled={currentPage == 1}
                                  >
                                    {"<<"}
                                  </Button>
                                  <Button
                                    color="primary"
                                    onClick={() => handlePrevious(skip)}
                                    disabled={currentPage == 1}
                                  >
                                    {"<"}
                                  </Button>
                                </div>
                              </Col>
                              <Col className="col-md-auto d-none d-md-block">
                                Page{" "}
                                <strong>{`${currentPage ? currentPage : 1} of ${
                                  totalPage ? totalPage : 1
                                }`}</strong>
                              </Col>
                              <Col className="col-md-auto">
                                <Input
                                  type="number"
                                  min={1}
                                  style={{ width: 70 }}
                                  max={total == 0 ? 1 : totalPage}
                                  value={currentPage || 1}
                                  defaultValue={1}
                                  onChange={onChangePagination}
                                  disabled={total == 0}
                                />
                              </Col>

                              <Col className="col-md-auto">
                                <div className="d-flex gap-1">
                                  <Button
                                    color="primary"
                                    onClick={() => handleNext(skip)}
                                    disabled={
                                      currentPage == totalPage || total == 0
                                    }
                                  >
                                    {">"}
                                  </Button>
                                  <Button
                                    color="primary"
                                    onClick={() =>
                                      handleNext((totalPage - 2) * limit)
                                    }
                                    disabled={
                                      currentPage == totalPage || total == 0
                                    }
                                  >
                                    {">>"}
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default DistributorAddendum
