export const StatusForUserInvestment = [
  "Initiated",
  "Commitment",
  "Awaiting_Token_Manual_Transfer",
  "Token_Paid",
  "EOI_Signed",
  "Awaiting_Manual_Transfer",
  "Partially_Paid",
  "Declined",
  // "Completed",
  "Initiate_Balance_Payment",
]

export const StatusObjForUserInvestment = [
  {
    statusText: "Initiated",
    id: "Initiated",
    colorClassName: "secondary",
  },
  {
    statusText: "Commitment",
    id: "Commitment",
    colorClassName: "secondary",
  },
  {
    statusText: "Awaiting Token Manual Transfer",
    id: "Awaiting_Token_Manual_Transfer",
    colorClassName: "warning",
  },
  {
    statusText: "Token Paid",
    id: "Token_Paid",
    colorClassName: "success",
  },
  {
    statusText: "EOI Signed",
    id: "EOI_Signed",
    colorClassName: "secondary",
  },
  {
    statusText: "Awaiting Manual Transfer",
    id: "Awaiting_Manual_Transfer",
    colorClassName: "warning",
  },
  {
    statusText: "Partially Paid",
    id: "Partially_Paid",
    colorClassName: "success",
  },
  {
    statusText: "Declined",
    id: "Declined",
    colorClassName: "danger",
  },
  {
    statusText: "Completed",
    id: "Completed",
    colorClassName: "success",
  },
  {
    statusText: "Initiate Balance Payment",
    id: "Initiate_Balance_Payment",
    colorClassName: "secondary",
  },
]

export const StatusObjForDistributorReport = [
  {
    statusText: "Upfront Fee",
    id: "upfront",
  },
  {
    statusText: "Trail Fee",
    id: "trail",
  },
]
export const benPosReportTypes = [
  {
    name: "Benpos Master",
    type: "",
    route: "Benpos Master",
    endpoint: "benpos-master",
  },
  {
    name: "BenPos Validation",
    type: "validation",
    route: "Benpos Master / Benpos Validation",
    endpoint: "benpos-report",
  },
  {
    name: "Interest Calculation",
    type: "interest",
    route: "Benpos Master / Interest Calculation",
    endpoint: "benpos-interest-calculation-report",
  },
  {
    name: "Uploaded Benpos",
    type: "uploaded",
    route: "Benpos Master / Uploaded Benpos",
    endpoint: "benpos",
  },
]

export const interestCalculationReports = [
  {
    id: "tranche-wise-per-bond",
    name: "Tranche-wise Interest (per bond)",
    endpoint: "tranche-wise-per-bond",
  },
  {
    id: "trade-wise",
    name: "Trade-wise Interest (per topup)",
    endpoint: "trade-wise",
  },
  {
    id: "tranche-wise",
    name: "Tranche-wise Interest (per user)",
    endpoint: "",
  },
  {
    id: "investor-wise",
    name: "Investor-wise Interest",
    endpoint: "investor-wise",
  },
]

export const pdfTemplates = [
  { statusText: "Securities Transfer Form", id: "STF" },
]
export const distributorInvoiceStatus = [
  { statusText: "GENERATED", id: "GENERATED", colorClassName: "secondary" },
  // { statusText: "PENDING", id: "PENDING" },
  { statusText: "SUBMITTED", id: "SUBMIT", colorClassName: "warning" },
  { statusText: "APPROVED", id: "APPROVED", colorClassName: "success" },
  { statusText: "RE SUBMIT", id: "RE_SUBMIT", colorClassName: "danger" },
  { statusText: "PAID", id: "PAID", colorClassName: "success" },
]

export const invStageForRm = [
  {
    statusText: "Initiated",
    id: "Initiated",
  },
  {
    statusText: "Commitment",
    id: "Commitment",
  },
  // {
  //   statusText: "Awaiting Token Manual Transfer",
  //   id: "Awaiting_Token_Manual_Transfer",
  // },
  // {
  //   statusText: "Token Paid",
  //   id: "Token_Paid",
  // },
  // {
  //   statusText: "EOI Signed",
  //   id: "EOI_Signed",
  // },
  {
    statusText: "Awaiting Manual Transfer",
    id: "Awaiting_Manual_Transfer",
  },
  {
    statusText: "Partially Paid",
    id: "Partially_Paid",
  },
  {
    statusText: "Declined",
    id: "Declined",
  },
  {
    statusText: "Initiate Balance Payment",
    id: "Initiate_Balance_Payment",
  },
]

export const transactionTypeForRepayment = [
  { statusText: "Interest Payment", id: "Interest_Payment" },
  { statusText: "Tds", id: "Tds" },
  { statusText: "Principal Repayment", id: "Principal_Repayment" },
  { statusText: "Principal Prepayment", id: "Principal_Prepayment" },
]
export const transactionTypeForTransactions = [
  { statusText: "Token", id: "Token" },
  { statusText: "Balance Investment", id: "Balance_Investment" },
  { statusText: "Direct", id: "Direct" },
  { statusText: "Token Refund", id: "Token_Refund" },
]
export const paymentStatusForTransactions = [
  { statusText: "Initiated", id: "initiated", colorClassName: "secondary" },
  { statusText: "Failed", id: "failed", colorClassName: "danger" },
  { statusText: "Success", id: "success", colorClassName: "success" },
]
export const kycStatusType = [
  {
    statusText: "IN VERIFICATION",
    id: "IN_VERIFICATION",
    colorClassName: "warning",
  },
  { statusText: "VERIFIED", id: "VERIFIED", colorClassName: "success" },
  { statusText: "RE SUBMIT", id: "RE_SUBMIT", colorClassName: "danger" },
  { statusText: "NOT SUBMITTED", id: "NOT_SUBMIT", colorClassName: "danger" },
]
export const investmentCountFilter = [
  { statusText: "Not Invested", id: "=0" },
  { statusText: "One Time Investor", id: "=1" },
  { statusText: "Repeat Investor", id: ">1" },
]
export const stfStatus = [
  { statusText: "Created", id: "CREATED", colorClassName: "secondary" },
  {
    statusText: "Sent For Sign",
    id: "SENT_FOR_SIGN",
    colorClassName: "warning",
  },
  { statusText: "Signed", id: "SIGNED", colorClassName: "success" },
  { statusText: "Cancelled", id: "CANCELLED", colorClassName: "danger" },
]
export const agreementStatus = [
  { statusText: "Created", id: "CREATED", colorClassName: "secondary" },
  {
    statusText: "Sent For Sign",
    id: "SENT_FOR_SIGN",
    colorClassName: "warning",
  },
  { statusText: "Signed", id: "SIGNED", colorClassName: "success" },
]

export const addendumStatus = [
  { statusText: "Not Created", id: "NOT_CREATED", colorClassName: "secondary" },
  { statusText: "Created", id: "CREATED", colorClassName: "secondary" },
  {
    statusText: "Sent For Sign",
    id: "SENT_FOR_SIGN",
    colorClassName: "warning",
  },
  { statusText: "Cancelled", id: "CANCELLED", colorClassName: "danger" },
  {
    statusText: "Pending Approval",
    id: "PENDING_APPROVAL",
    colorClassName: "primary",
  },

  { statusText: "Signed", id: "SIGNED", colorClassName: "success" },
]

export const PaymentTypeForRepayment = [
  "Online",
  "Bank_Transfer",
  "Cash",
  "Cheque",
  "RTGS",
  "IMPS",
  "NEFT",
  "UPI",
]

export const webhookPaymentModes = [
  {
    statusText: "RTGS",
    id: "RTGS",
  },
  {
    statusText: "IMPS",
    id: "IMPS",
  },
  {
    statusText: "NEFT",
    id: "NEFT",
  },
  {
    statusText: "UPI",
    id: "UPI",
  },
  {
    statusText: "Online",
    id: "Online",
  },
  {
    statusText: "Bank Transfer",
    id: "Bank_Transfer",
  },
  {
    statusText: "Cash",
    id: "Cash",
  },
  {
    statusText: "Cheque",
    id: "Cheque",
  },
]

export const AccessId = {
  USER_MANAGEMENT: 1,
  KYC: 2,
  DEAL_PUBLISHING: 3,
  TRANSACTION: 4,
  SUPPORT: 5,
  ASSET_MANAGEMENT: 6,
  DISTRIBUTOR_MANAGEMENT: 7,
  DISTRIBUTOR_PORTAL: 8,
  SALES_INVESTOR_SERVICING: 9,
  DASHBOARD: 10,
  REPORTS: 11,
}

export const PermissionNotDefied =
  "Your Permission is not defined , Please contact approver !!"

export const billingFrequencyEnums = [
  { enum: "SEMI_MONTHLY", name: "SEMI-MONTHLY" },
  { enum: "MONTHLY", name: "MONTHLY" },
]

export const defaultSignatory = {
  name: "Ashish Khandelia",
  email: "ashish.khandelia@certuscapital.in",
}
