import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Link } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import * as moment from "moment"
import { axiosInstance } from "ConfigAxioxinstance"
import { toast } from "react-toastify"
import { JSONToCSVConvertor } from "common/jsontocsv"
import { csvDownloadData, humanize, investmentData } from "constants/common"
import ReactSelect from "constants/ReactSelect"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledTooltip,
  Input,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"

import Breadcrumbs from "components/Common/Breadcrumb"
import { distributorUsers } from "constants/common"
import Loader from "common/Loader"
import { DealManagementAccess } from "common/AccessManagement"
import {
  AccessId,
  addendumStatus,
  defaultSignatory,
} from "constants/ConstantFields"
import { sortingByAlphabet } from "constants/sort"

const ApproveAddendums = () => {
  const [orders, setOrders] = useState([])
  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [total, setTotal] = useState(0)

  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(false)
  const [selectedAddendum, setSelectedAddendum] = useState({})
  const [investments, setInvestments] = useState([])
  const [userFilter, setUserFilter] = useState("")
  const [investmentFilter, setInvestmentFilter] = useState({})
  const [finalSelectedRows, setFinalSelectedRows] = useState([])
  const [addendumStatusFilter, setAddendumStatusFilter] = useState({})
  const [consent, setConsent] = useState(false)

  const filterUrl = `&signing_status=PENDING_APPROVAL${
    userFilter.length >= 3 ? `&name=${userFilter}` : ``
  }${investmentFilter?.id ? `&investmentId=${investmentFilter.id}` : ""}${
    addendumStatusFilter?.id ? `&signing_status=${addendumStatusFilter.id}` : ""
  }`
  const masterData = async () => {
    setLoading(true)
    try {
      const response = await axiosInstance.get(
        `/distributor-addendum?$limit=${limit}&$skip=${skip}&$sort[updated_at]=-1${filterUrl}`
      )

      if (response) {
        setOrders(response.data?.data || response.data)
        setTotal(response.data.total)
        let pages = Math.ceil(
          (response.data?.total || response.total) /
            (response.data?.limit || response.limit)
        )
        setTotalPage(pages)
      }
    } catch (error) {
      toast.error(error?.message)
    }
    setLoading(false)
  }

  useEffect(async () => {
    setLoading(true)
    const investmentRes = await investmentData("Approved", "Closed", "Exited")
    const investments = investmentRes?.data?.data || investmentRes?.data

    if (investments) {
      setInvestments(investments)
      setLoading(false)
    } else {
      toast.error("something went wrong")
      setLoading(false)
    }
  }, [])

  const toggle = () => {
    setModal(!modal)
  }
  useEffect(async () => {
    masterData()
  }, [investmentFilter, limit, skip, addendumStatusFilter])

  const handleNext = prev => {
    setSkip(prev + limit)
    let page = (prev + limit) / limit + 1
    setCurrentPage(page)
  }

  const handlePrevious = prev => {
    setSkip(prev - limit)
    let page = (prev - limit) / limit + 1
    setCurrentPage(page)
  }

  const selectRow = {
    mode: "checkbox",
    clickToSelect: false,
    clickToExpand: true,
    onSelect: (row, isSelect) => {
      if (isSelect) {
        setFinalSelectedRows([...finalSelectedRows, row])
      } else {
        setFinalSelectedRows(finalSelectedRows.filter(r => r.id !== row.id))
      }
    },
    onSelectAll: (isSelect, rows) => {
      setFinalSelectedRows(isSelect ? rows : [])
    },
    selected: finalSelectedRows.map(row => row.id),
  }
  const handleFilterChange = async (e, key) => {
    switch (key) {
      case "user":
        setUserFilter(e.target.value)
        break
      case "projectType":
        setInvestmentFilter(e)
        break
      case "addendum_status":
        setAddendumStatusFilter(e)
        break
      case "limit":
        setLimit(Number(e.target.value))
        break
      default:
        break
    }
    setSkip(0)
    setCurrentPage(1)
  }

  const onChangePagination = e => {
    const { value } = e.target
    if (value < 1 || value > totalPage) {
      return
    }
    setCurrentPage(value)
    if (value) {
      setSkip((value - 1) * limit)
    }
  }

  useEffect(() => {
    if (!userFilter || userFilter?.length >= 3) {
      masterData()
    }
  }, [userFilter])

  const accessRestriction = DealManagementAccess(AccessId?.DISTRIBUTOR_PORTAL)

  const handleRowClick = selected => {
    setConsent(false)
    setSelectedAddendum(selected)
    toggle()
  }
  const AddendumColumns = download => {
    let cols = []
    if (!download) {
      cols.push({
        dataField: "",
        text: "Action",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => (
          <Link to="#" className="text-primary">
            <Button
              type="button"
              color="success"
              className="btn-md btn-rounded"
              onClick={() => handleRowClick(row)}
            >
              Approve
            </Button>
          </Link>
        ),
      })
    }
    cols.push(
      {
        dataField: "legal_entity_name",
        text: "Legal Entity Name",
        sort: true,
        formatter: (cellContent, row) =>
          row?.distributor?.distributor_kyc?.name,
      },
      {
        dataField: "investment.project_name",
        text: "Project Name",
        sort: true,
      },
      {
        dataField: "addendum_no",
        text: "Current Addendum No.",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => (
          <div className="text-end">{cellContent}</div>
        ),
      },
      {
        dataField: "upfront_fee_applicable_date",
        text: "Current Upfront Fee Applicable Date",
        sort: true,
        formatter: (cellContent, row) =>
          row?.upfront_fee_applicable_date
            ? handleValidDate(row?.upfront_fee_applicable_date)
            : "NA",
      },
      {
        dataField: "upfront_fee_percent",
        text: "Current Upfront Fee percent",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => (
          <div className="text-end">{Number(cellContent).toFixed(2)}</div>
        ),
      },

      //   {
      //     dataField: "unsigned_doc_url",
      //     text: "Unsigned Addendum",
      //     sort: true,
      //     // eslint-disable-next-line react/display-name
      //     formatter: (cellContent, order) =>
      //       order.unsigned_doc_url ? (
      //         <>
      //           <div className="d-flex gap-3">
      //             <Link
      //               to="#"
      //               className="text-primary"
      //               onClick={() => handleDoc(order?.unsigned_doc_url)}
      //             >
      //               <svg viewBox="0 0 24 24" width="26px" fill={"#556ee6"}>
      //                 <path d="M6,2A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2H6M6,4H13V9H18V20H6V4M8,12V14H16V12H8M8,16V18H13V16H8Z" />
      //               </svg>
      //             </Link>
      //           </div>
      //         </>
      //       ) : (
      //         "NA"
      //       ),
      //   },
      //   {
      //     dataField: "addendum_doc_url",
      //     text: "Signed Addendum",
      //     sort: true,
      //     // eslint-disable-next-line react/display-name
      //     formatter: (cellContent, order) =>
      //       order.addendum_doc_url ? (
      //         <>
      //           <div className="d-flex gap-3">
      //             <Link
      //               to="#"
      //               className="text-primary"
      //               onClick={() => handleDoc(order?.addendum_doc_url)}
      //             >
      //               <svg viewBox="0 0 24 24" fill={"#556ee6"} width="26px">
      //                 <path d="M19.7 12.9L14 18.6H11.7V16.3L17.4 10.6L19.7 12.9M23.1 12.1C23.1 12.4 22.8 12.7 22.5 13L20 15.5L19.1 14.6L21.7 12L21.1 11.4L20.4 12.1L18.1 9.8L20.3 7.7C20.5 7.5 20.9 7.5 21.2 7.7L22.6 9.1C22.8 9.3 22.8 9.7 22.6 10C22.4 10.2 22.2 10.4 22.2 10.6C22.2 10.8 22.4 11 22.6 11.2C22.9 11.5 23.2 11.8 23.1 12.1M3 20V4H10V9H15V10.5L17 8.5V8L11 2H3C1.9 2 1 2.9 1 4V20C1 21.1 1.9 22 3 22H15C16.1 22 17 21.1 17 20H3M11 17.1C10.8 17.1 10.6 17.2 10.5 17.2L10 15H8.5L6.4 16.7L7 14H5.5L4.5 19H6L8.9 16.4L9.5 18.7H10.5L11 18.6V17.1Z" />
      //               </svg>
      //             </Link>
      //           </div>
      //         </>
      //       ) : (
      //         "NA"
      //       ),
      //   },

      {
        dataField: "signed_on",
        text: "Current Signed On",
        sort: true,
        formatter: (cellContent, row) =>
          row?.signed_on ? handleValidDate(row?.signed_on) : "NA",
      },

      {
        dataField: "additional_fee_percent",
        text: "Current Trail Fee Percent",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => (
          <div className="text-end">{cellContent}</div>
        ),
      },
      {
        dataField: "additional_fee_payment_frequency",
        text: "Current Trail Fee Payment Frequency",
        sort: true,
      },
      {
        dataField: "trail_fee_applicable_days",
        text: "Current Trail Fee Applicable Days",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => (
          <div className="text-end">{cellContent}</div>
        ),
      },
      {
        dataField: "minimum_aggregate_face_value",
        text: "Current Minimum Business Volume",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => (
          <div className="text-end">{cellContent}</div>
        ),
      },

      {
        dataField: "oldAddendum.addendum_no",
        text: "Old Addendum No.",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => (
          <div className="text-end">{cellContent}</div>
        ),
      },
      {
        dataField: "oldAddendum.upfront_fee_applicable_date",
        text: "Old Upfront Fee Applicable Date",
        sort: true,
        formatter: (cellContent, row) =>
          row?.upfront_fee_applicable_date
            ? handleValidDate(row?.upfront_fee_applicable_date)
            : "NA",
      },
      {
        dataField: "oldAddendum.upfront_fee_percent",
        text: "Old Upfront Fee percent",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => (
          <div className="text-end">{Number(cellContent).toFixed(2)}</div>
        ),
      },
      {
        dataField: "oldAddendum.signed_on",
        text: "Old Signed On",
        sort: true,
        formatter: (cellContent, row) =>
          row?.signed_on ? handleValidDate(row?.signed_on) : "NA",
      },

      {
        dataField: "oldAddendum.additional_fee_percent",
        text: "Old Trail Fee Percent",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => (
          <div className="text-end">{cellContent}</div>
        ),
      },
      {
        dataField: "oldAddendum.additional_fee_payment_frequency",
        text: "Old Trail Fee Payment Frequency",
        sort: true,
      },
      {
        dataField: "oldAddendum.trail_fee_applicable_days",
        text: "Old Trail Fee Applicable Days",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => (
          <div className="text-end">{cellContent}</div>
        ),
      },
      {
        dataField: "oldAddendum.minimum_aggregate_face_value",
        text: "Old Minimum Business Volume",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => (
          <div className="text-end">{cellContent}</div>
        ),
      },
      { dataField: "distributor.user_name", text: "User Name", sort: true },
      { dataField: "distributor.email", text: "Email", sort: true },
      { dataField: "distributor.phone", text: "Phone", sort: true }
    )

    return cols
  }

  const handleDoc = async file => {
    setLoading(true)
    try {
      const response = await axiosInstance.post("/get-signed-url", {
        fileName: file,
      })
      if (response) {
        window.open(response?.data)
      }
    } catch (error) {
      toast.error(error?.message)
    }
    setLoading(false)
  }

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]

  const downloadData = async () => {
    try {
      setLoading(true)

      const fileName = "Distributor Addendum For Approval"

      const res = await axiosInstance.get(
        `/distributor-addendum?$sort[updated_at]=-1${filterUrl}`
      )
      if (res) {
        const data = res.data?.data
        const csvTableHeaders = AddendumColumns(true)
        const str = JSON.stringify(data)
        const arr = JSON.parse(str)
        arr?.forEach(item => {
          item.legal_entity_name = item?.distributor?.distributor_kyc?.name
          item.upfront_fee_applicable_date = item.upfront_fee_applicable_date
            ? handleValidDate(item.upfront_fee_applicable_date)
            : ""
          item.upfront_fee_percent = Number(item.upfront_fee_percent).toFixed(2)
          item.status = item.status ? "Active" : "Inactive"
          item.signed_on = item.signed_on ? handleValidDate(item.signed_on) : ""
          item.signatory = item?.distributor?.distributor_kyc?.signatory || ""
        })
        const downladableArr = csvDownloadData(csvTableHeaders, arr)
        JSONToCSVConvertor(downladableArr, fileName, true)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      toast.error(error?.message)
    }
  }

  const handleValidDate = date => {
    const date1 = moment(date).format("DD MMM Y")
    return date1
  }

  const handleApprove = async () => {
    try {
      setLoading(true)
      const res = await axiosInstance.delete(
        `/distributor-addendum/${selectedAddendum.id}?approved=true`
      )
      if (res) {
        await masterData()
        toggle()
        toast.success(
          "Approved: Old terms reinstated, current addendum deleted"
        )
      }
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }
  const handleRejectChanges = async () => {
    try {
      setLoading(true)
      const res = await axiosInstance.post("/generate-distributor-pdf", {
        type: "addendum",
        distributorAddendumId: selectedAddendum.id,
      })
      if (res) {
        await masterData()
        toggle()
        toast.success("Request rejected - Current terms maintained")
      }
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }
  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs breadcrumbItem="Approve Addendum" />
          <Row>
            <Col xs="12">
              <Row className="mb-2 row justify-content-between">
                <Col md={2}>
                  <select
                    className="form-select w-75"
                    value={limit}
                    onChange={e => handleFilterChange(e, "limit")}
                  >
                    {[10, 30, 50, 100].map(pageSize => (
                      <option key={pageSize} value={pageSize}>
                        Show {pageSize}
                      </option>
                    ))}
                  </select>
                </Col>
                <div className="col-auto">
                  {(accessRestriction >= "4" ||
                    accessRestriction === "SuperAdmin") && (
                    <Button
                      type="button"
                      color="primary"
                      className="btn-rounded mb-2 me-2"
                      onClick={downloadData}
                      style={{ marginRight: "3px" }}
                    >
                      <i className="mdi mdi-arrow-down-bold-circle"></i>{" "}
                      Download
                    </Button>
                  )}
                </div>
              </Row>
              <Card>
                <CardBody>
                  <ToolkitProvider
                    keyField="id"
                    data={orders}
                    columns={AddendumColumns()}
                    bootstrap4
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row className="mb-2 row">
                          <Col md={3}>
                            <div className="position-relative">
                              <label>Search User</label>
                              <input
                                onChange={e => handleFilterChange(e, "user")}
                                id="search-bar-0"
                                type="text"
                                className="form-control rounded custom-input-height"
                                placeholder={`Name, Email, Phone, Pan Name or Pan`}
                                value={userFilter || ""}
                              />
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className="mb-3">
                              <label>Project Name</label>
                              <ReactSelect
                                users={investments}
                                setSelectedOption={e =>
                                  handleFilterChange(e, "projectType")
                                }
                                multiOptionLabel={true}
                                optionLabelKeys={["project_name"]}
                                isClearable={true}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl="12">
                            <BootstrapTable
                              keyField="id"
                              bordered={false}
                              striped={false}
                              defaultSorted={defaultSorted}
                              selectRow={selectRow}
                              columns={AddendumColumns()}
                              data={orders}
                              wrapperClasses={"table-responsive mb-4"}
                              classes={
                                "table align-middle table-nowrap table-check"
                              }
                              headerWrapperClasses={"table-light"}
                              {...toolkitProps.baseProps}
                            />
                          </Col>
                        </Row>
                        <Row className="justify-content-md-space-between justify-content-center align-items-center">
                          <Col className="col-12 col-md-auto mb-3">
                            {`Showing ${total ? skip + 1 + " to" : ""} ${
                              limit > total || limit + skip > total
                                ? total
                                : limit + skip
                            } rows of ${total}${
                              finalSelectedRows.length
                                ? ` | Selected rows: ${finalSelectedRows.length}`
                                : ""
                            }
                    `}
                          </Col>
                          <Col>
                            <Row className="justify-content-md-end justify-content-center align-items-center">
                              <Col className="col-md-auto">
                                <div className="d-flex gap-1">
                                  <Button
                                    color="primary"
                                    onClick={() => handlePrevious(limit)}
                                    disabled={currentPage == 1}
                                  >
                                    {"<<"}
                                  </Button>
                                  <Button
                                    color="primary"
                                    onClick={() => handlePrevious(skip)}
                                    disabled={currentPage == 1}
                                  >
                                    {"<"}
                                  </Button>
                                </div>
                              </Col>
                              <Col className="col-md-auto d-none d-md-block">
                                Page{" "}
                                <strong>{`${currentPage ? currentPage : 1} of ${
                                  totalPage ? totalPage : 1
                                }`}</strong>
                              </Col>
                              <Col className="col-md-auto">
                                <Input
                                  type="number"
                                  min={1}
                                  style={{ width: 70 }}
                                  max={total == 0 ? 1 : totalPage}
                                  value={currentPage || 1}
                                  defaultValue={1}
                                  onChange={onChangePagination}
                                  disabled={total == 0}
                                />
                              </Col>

                              <Col className="col-md-auto">
                                <div className="d-flex gap-1">
                                  <Button
                                    color="primary"
                                    onClick={() => handleNext(skip)}
                                    disabled={
                                      currentPage == totalPage || total == 0
                                    }
                                  >
                                    {">"}
                                  </Button>
                                  <Button
                                    color="primary"
                                    onClick={() =>
                                      handleNext((totalPage - 2) * limit)
                                    }
                                    disabled={
                                      currentPage == totalPage || total == 0
                                    }
                                  >
                                    {">>"}
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle} tag="h4">
          Confirmation
        </ModalHeader>
        <ModalBody>
          <Row className="px-2">
            <h5>Current Addendum Terms</h5>
            <ul className="mx-3">
              <li>
                Addendum No.: <strong>{selectedAddendum?.addendum_no}</strong>
              </li>
              <li>
                Upfront Fee Applicable Date:{" "}
                <strong>
                  {handleValidDate(
                    selectedAddendum?.upfront_fee_applicable_date
                  )}
                </strong>
              </li>
              <li>
                Upfront Fee percent:{" "}
                <strong>
                  {selectedAddendum?.upfront_fee_percent
                    ? Number(selectedAddendum.upfront_fee_percent).toFixed(2)
                    : 0}
                </strong>
              </li>
              <li>
                Trail Fee Percent:{" "}
                <strong>{selectedAddendum?.additional_fee_percent}</strong>
              </li>
              <li>
                Trail Fee Payment Frequency:{" "}
                <strong>
                  {selectedAddendum?.additional_fee_payment_frequency}
                </strong>
              </li>
              <li>
                Trail Fee Applicable Days:{" "}
                <strong>{selectedAddendum?.trail_fee_applicable_days}</strong>
              </li>
              <li>
                Minimum Business Volume:{" "}
                <strong>
                  {selectedAddendum?.minimum_aggregate_face_value}
                </strong>
              </li>
            </ul>
            <br />
            <h5>Old Addendum Terms</h5>
            <ul className="mx-3">
              <li>
                Addendum No.:{" "}
                <strong>{selectedAddendum?.oldAddendum?.addendum_no}</strong>
              </li>
              <li>
                Upfront Fee Applicable Date:{" "}
                <strong>
                  {handleValidDate(
                    selectedAddendum?.oldAddendum?.upfront_fee_applicable_date
                  )}
                </strong>
              </li>
              <li>
                Upfront Fee percent:{" "}
                <strong>
                  {selectedAddendum?.oldAddendum?.upfront_fee_percent
                    ? Number(
                        selectedAddendum.oldAddendum?.upfront_fee_percent
                      ).toFixed(2)
                    : 0}
                </strong>
              </li>
              <li>
                Trail Fee Percent:{" "}
                <strong>
                  {selectedAddendum?.oldAddendum?.additional_fee_percent}
                </strong>
              </li>
              <li>
                Trail Fee Payment Frequency:{" "}
                <strong>
                  {
                    selectedAddendum?.oldAddendum
                      ?.additional_fee_payment_frequency
                  }
                </strong>
              </li>
              <li>
                Trail Fee Applicable Days:{" "}
                <strong>
                  {selectedAddendum?.oldAddendum?.trail_fee_applicable_days}
                </strong>
              </li>
              <li>
                Minimum Business Volume:{" "}
                <strong>
                  {selectedAddendum?.oldAddendum?.minimum_aggregate_face_value}
                </strong>
              </li>
            </ul>
          </Row>
          <Row>
            <AvForm>
              <div className="d-flex gap-1 mb-3">
                <AvField
                  name="consent_box"
                  type="checkbox"
                  id="consent"
                  checked={consent}
                  onChange={e => setConsent(e.target.checked)}
                />{" "}
                <label
                  className="mb-0 font-size-14 text-muted"
                  htmlFor="consent"
                >
                  I have reviewed the changes to the addendum terms.
                </label>
              </div>
            </AvForm>
          </Row>
          <Row>
            <Col>
              <div className={"d-flex justify-content-end mt-3 gap-2"}>
                <button
                  type="button"
                  className="btn btn-outline-secondary save-user"
                  disabled={loading}
                  onClick={handleRejectChanges}
                >
                  Reject Changes
                </button>
                <button
                  type="button"
                  className="btn btn-success save-user"
                  disabled={loading || !consent}
                  onClick={handleApprove}
                >
                  Approve Changes
                </button>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default ApproveAddendums
